<template>
    <nav class="navbar navbar-expand-lg fixed-top top-header">

        <div class="d-flex align-items-center">
            <button aria-label="Toggle navigation" class="navbar-toggler text-white" type="button">
                <fa icon="bars"/>
            </button>
            <router-link class="navbar-brand" to="/">
                <h3 class="text-dark">{{ headerText }}</h3>
            </router-link>
        </div>


    </nav>
</template>

<script>

export default {
    name: 'Navbar',
    props: {
        headerText: {
            type: String,
            required: true
        }
    }
}
</script>
