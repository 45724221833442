<template>
    <div class="wizard-layout" :style="wizardBgStyle">
        <div class="panel-container">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import wizardBg from '../assets/images/wizard-bg.png';

    export default {
        name: "WizardLayout",
        computed: {
            wizardBgStyle() {
                return {
                    backgroundImage: `url(${wizardBg})`
                }
            }
        }
    }
</script>
