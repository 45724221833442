<template>
    <div class="d-flex">
        <div class="logo">
            <fa class="icon-left" icon="quote-right" flip="horizontal"></fa>
            <fa class="icon-right" icon="quote-left" flip="horizontal"></fa>
        </div>
        <div class="logo-text" v-show="showText">
            <div class="upper">WayWithWords</div>
            <div class="lower">Workflow System</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Logo",
        props: {
            showText: {
                type: Boolean,
                default: true
            }
        }
    }
</script>
