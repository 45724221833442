<template>
    <div class="theme-wrapper">
        <side-nav/>
        <div class="page-container">
            <navbar :header-text="headerText"/>
            <div class="bg-light page-content">
                <transition mode="out-in" name="fade">
                    <router-view></router-view>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "../components/Navbar";
import SideNav from "../components/SideNav";

export default {
    name: 'ThemeLayout',
    components: {
        SideNav,
        Navbar
    },
    data() {
        return {
            headerText: 'Welcome'
        }
    },
    watch: {
        $route(route) {
            this.headerText = route.name
        }
    }
}
</script>
