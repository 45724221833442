import Vue from 'vue'
import App from './App.vue'
import router from './router'

// Bootstrap
import 'bootstrap';
import jQuery from 'jquery';
import Popper from "popper.js";
// Bootstrap Vue
import {BootstrapVue} from "bootstrap-vue";
// Font Awesome
import {dom as faDom, library as faLibrary} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
// App styles
import '@/assets/scss/style.scss'
//Vue Toast
import VueToast from 'vue-toast-notification';

// Theme scripts
import '@/theme';

window.Popper = Popper;
window.$ = window.jQuery = jQuery;

Vue.use(BootstrapVue);
Vue.use(VueToast);

faLibrary.add(fas, far, fab);
faDom.watch();

Vue.component('fa', FontAwesomeIcon);

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
