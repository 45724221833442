<template>
    <div :class="['menu', 'bg-primary', {'hide' : !showMenu}]">
        <div class="header" @click="$emit('toggleMenu','main')">
            <logo :show-text="showMenu"></logo>
        </div>
        <div class="menu-list-container">
            <menu-list class="text-light" :menu="menu" :show-text="showMenu"></menu-list>
        </div>

        <div class="mt-auto">
            <menu-list class="text-light" :menu="notification" :show-text="showMenu"></menu-list>
        </div>

        <div class="user-info-container">
            <div class="py-2">
                <avatar type="image" src="https://i.pravatar.cc/150?img=37" size="sm"></avatar>
            </div>
            <div class="ml-2" v-show="showMenu">
                <div class="font-12">Welcome</div>
                <div class="font-weight-medium">Name Surname</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Logo from "../Logo/Logo";
    import MenuList from "../MenuList/MenuList";
    import Avatar from "../Avatar/Avatar";

    export default {
        name: "MainMenu",
        components: {
            Logo,
            MenuList,
            Avatar
        },
        props: {
          showMenu: {
              type: Boolean,
              default: true
          }
        },
        data() {
            return {

                menu: [
                    {
                        title: 'All Jobs',
                        icon: 'pen-square',
                        children: [
                            {
                                title: 'Job Pool'
                            },
                            {
                                title: 'Issue Manger'
                            },
                            {
                                title: 'Deadline Manger'
                            },
                            {
                                title: 'Past Jobs'
                            }
                        ]
                    },
                    {
                        title: 'All Jobs',
                        icon: 'pen-square',
                        children: [
                            {
                                title: 'Job Pool'
                            },
                            {
                                title: 'Issue Manger'
                            },
                        ]
                    },
                    {
                        title: 'All Jobs',
                        icon: 'pen-square',
                        children: [
                            {
                                title: 'Job Pool'
                            },
                            {
                                title: 'Issue Manger'
                            },
                        ]
                    }
                ],

                notification: [
                    {
                        title: '',
                        icon: 'pen-square',
                    },
                    {
                        title: 'Notifications',
                        icon: 'pen-square',
                    }
                ]
            }
        }
    }
</script>
