<template>
    <div class="d-flex flex-column menu-list">
        <div class="menu-item" v-for="(item,index) in menu" :key="index">
            <div class="menu-item_icon">
                <fa v-if="item.icon" :icon="item.icon" size="lg"></fa>
            </div>
            <div v-if="item.children && item.children.length > 0" v-show="showText">
                <div class="menu-item_text" data-toggle="collapse" v-b-toggle="'#collapse'+_uid+index">{{ item.title }}
                </div>
                <b-collapse visible :id="'#collapse'+_uid+index" v-show="showText">
                    <div class="menu-item_text child-item" :class="{'active': child.active}" v-for="(child,index) in item.children" :key="index">{{ child.title }}</div>
                </b-collapse>
            </div>

            <div v-else v-show="showText" class="menu-item_text">{{ item.title }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MenuList",
        props: {
            menu: {
                type: Array,
                default: () => []
            },
            showText: {
                type: Boolean,
                default: true
            }
        }
    }
</script>
