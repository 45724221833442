<template>
    <div class="d-flex justify-content-start">
        <main-menu :show-menu="showMenu" @toggleMenu="toggleMenu"></main-menu>
        <secondary-menu @toggleMenu="toggleMenu"  v-if="showSecondaryMenu"></secondary-menu>
        <router-view @toggleMenu="toggleMenu"  name="mainView"></router-view>
        <div class="panel-container">
            <div class="overlay"></div>
            <router-view name="panelView"></router-view>
        </div>
    </div>
</template>

<script>
    import MainMenu from "../components/MainMenu/MainMenu";
    import SecondaryMenu from "../components/SecondaryMenu/SecondaryMenu";

    export default {
        name: "MainNavigationLayout",
        components: {
            MainMenu,
            SecondaryMenu
        },
        data() {
            return {
                showMenu: true,
                showSecondaryMenu: true
            }
        },
        methods: {
            toggleMenu(menu, value = null) {
                if(value) {
                    if(menu === 'main') {
                        this.showMenu = value;
                    }
                    else if(menu === 'secondary') {
                        this.showSecondaryMenu =  value;
                    }
                }

                if(menu === 'main') {
                    this.showMenu = !this.showMenu;
                }
                else if(menu === 'secondary') {
                    this.showSecondaryMenu = !this.showSecondaryMenu;
                }
            },
        }
    }
</script>
