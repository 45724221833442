<template>
    <div :class="[ 'avatar', {[`avatar-${size}`]: true}]">

        <span v-if="type == 'text'" class="avatar-title rounded-circle" :class="{[`bg-${variant}`]: true}">
            <slot></slot>
        </span>

        <span v-else-if="type == 'icon'" class="avatar-icon rounded-circle" :class="{[`bg-${variant}`]: true}">
            <slot></slot>
        </span>

        <img v-else-if="type == 'image'" :class="[`img-fluid rounded-circle`]"
             :alt="alt"
             :src="src">

        <div v-if="withBadge" :class="['avatar-badge', {[`${badgePosition}`]: true} ]"></div>

    </div>
</template>

<script>
import avatarPlaceholder from '../../assets/images/avatar-placeholder.svg';

export default {
    name: 'Avatar',
    props: {
        src: String,
        alt: String,
        variant: {
            type: String,
            default: 'primary'
        },
        type: {
            type: String,
            default: 'text',
            validator: function (value) {
                return ['text', 'image', 'icon'].indexOf(value) !== -1
            }
        },
        size: {
            type: String,
            default: 'md',
            validator: function (value) {
                return ['xs', 'sm', 'md', 'lg', 'xl'].indexOf(value) !== -1
            }
        },
        withBadge: {
            type: Boolean,
            default: false
        },
        badgePosition: {
            type: String,
            default: 'bottom right'
        }
    },
    computed: {
        imgSrc() {
            return this.src || avatarPlaceholder;
        }
    }
}
</script>
