import $ from 'jquery';

const Theme = {};

Theme.init = function () {
    const body = $('body');

    // Enable tooltip
    $('[data-toggle="tooltip"]').tooltip();

    body.on('click', '.navbar-toggler', this.handleNavbarToggler);
    body.on('click', '.menu-link', this.handleMenuToggle);
}

Theme.handleNavbarToggler = function (ev) {
    ev.preventDefault();

    $('#side-nav').toggleClass('show');
}

Theme.handleMenuToggle = function () {
    const menuItem = $(this).closest('.menu-item-parent');

    // Hide other sub menu
    $('.menu-item-parent').not(menuItem).removeClass('open').find('.sub-menu').slideUp(100);

    // Show current item's sub menu
    menuItem.find('.sub-menu').slideToggle(100);
}

window.Theme = Theme;
