<template>
    <div :class="['menu', 'secondary', 'bg-custom-light-dark', {'hide' : !showMenu}]">
        <div class="header border-bottom px-4 d-flex align-items-center" @click.prevent="$emit('toggleMenu','main')">
            <div class="font-24" >
                All Jobs
            </div>
            <fa class="ml-auto" icon="chevron-left" size="lg"></fa>
        </div>
        <div class="mt-2">
            <menu-list class="text-gray-500 font-14" :menu="tools" :show-text="showMenu"></menu-list>
            <menu-list class="text-dark" :menu="menu" :show-text="showMenu"></menu-list>
        </div>

    </div>
</template>

<script>
    import MenuList from "../MenuList/MenuList";

    export default {
        name: "MainMenu",
        components: {
            MenuList,
        },
        data() {
            return {
                showMenu: true,
                menu: [
                    {
                        title: 'All Jobs',
                        icon: 'pen-square',
                        children: [
                            {
                                title: 'Job Pool',
                                active: true
                            },
                            {
                                title: 'Issue Manger'
                            },
                            {
                                title: 'Deadline Manger'
                            },
                            {
                                title: 'Past Jobs'
                            }
                        ]
                    },
                    {
                        title: 'All Jobs',
                        icon: 'pen-square',

                    },
                    {
                        title: 'All Jobs',
                        icon: 'pen-square',
                    },
                    {
                        title: 'All Jobs',
                        icon: 'pen-square',
                    }
                ],

                tools: [
                    {
                        title: 'Tools',
                    },
                ]
            }
        }
    }
</script>
