<template>
    <div id="side-nav" class="side-nav">
        <div class="side-nav-header">
            <h3>Design System</h3>
        </div>
        <div class="side-nav-content">
            <ul class="side-nav-menu">
                <li class="menu-title">Components</li>
                <li class="menu-item">
                    <router-link class="menu-link" to="/layout">Layout</router-link>
                </li>
                <li class="menu-item">
                    <router-link class="menu-link" to="/alerts">Alerts</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="avatars" class="menu-link">Avatars</router-link>
                </li>
                <li class="menu-item">
                    <router-link class="menu-link" to="/badge">Badges</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/breadcrumb" class="menu-link">Breadcrumbs</router-link>
                </li>
                <li class="menu-item">
                    <router-link class="menu-link" to="/buttons">Buttons</router-link>
                </li>
                <li class="menu-item">
                    <router-link class="menu-link" to="/calendar">Calendars</router-link>
                </li>
                <li class="menu-item">
                    <router-link class="menu-link" to="/cards">Cards</router-link>
                </li>
                <li class="menu-item">
                    <router-link class="menu-link" to="/dropdown">Dropdown</router-link>
                </li>
                <li class="menu-item">
                    <router-link class="menu-link" to="/modal">Modals</router-link>
                </li>
                <li class="menu-item">
                    <router-link class="menu-link" to="/notifications">Notifications</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/pagination" class="menu-link">Pagination</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/panels" class="menu-link">Panels</router-link>
                </li>
                <li class="menu-item">
                    <router-link class="menu-link" to="/progress">Progress</router-link>
                </li>
                <li class="menu-item">
                    <router-link class="menu-link" to="/spinners">Spinners</router-link>
                </li>
                <li class="menu-item">
                    <router-link class="menu-link" to="/table">Tables</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/tabs" class="menu-link">Tabs</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/time" class="menu-link">Time</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/typography" class="menu-link">Typography</router-link>
                </li>
                <li class="menu-item">
                    <router-link class="menu-link" to="/input">Input Fields</router-link>
                </li>
                <li class="menu-item">
                    <router-link class="menu-link" to="/colour">Colours</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/popover" class="menu-link" >Popovers</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/navbar" class="menu-link" >Navbar</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/kanban" class="menu-link" >Kanban</router-link>
                </li>
                <li class="menu-title">Layouts</li>
                <li class="menu-item">
                    <router-link to="/layouts/menu" class="menu-link" >Menu</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/layouts/off-canvas" class="menu-link" >Off Canvas -Sets</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/layouts/dashboard" class="menu-link" >Dashboard</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/layouts/page-layouts" class="menu-link" >Page Layouts</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/layouts/profile" class="menu-link" >Profile</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/layouts/profile-off-canvas" class="menu-link" >Profile - Off Canvas</router-link>
                </li>
                <li class="menu-item">
                    <router-link to="/page/wizard-navigation" class="menu-link" >Wizard Navigation</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SideNav',
}
</script>
