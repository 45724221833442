import Vue from 'vue'
import VueRouter from 'vue-router'
import ThemeLayout from '../layouts/ThemeLayout';
import MainNavigationLayout from "../../src/layouts/MainNavigationLayout";
import WizardLayout from "../../src/layouts/WizardLayout";

Vue.use(VueRouter)

export const routes = [

    {
        path: '/',
        component: ThemeLayout,
        children: [
            {
                path: 'layout',
                name: 'layout',
                component: () => import(/* webpackChunkName: "ui-layouts" */ '../views/ui/Layout.vue')
            },
            {
                path: 'alerts',
                name: 'alerts',
                component: () => import(/* webpackChunkName: "ui-alerts" */ '../views/ui/Alerts.vue')
            },
            {
                path: 'avatars',
                name: 'avatars',
                component: () => import(/* webpackChunkName: "ui-avatars" */ '../views/ui/Avatars.vue')
            },
            {
                path: 'badge',
                name: 'badges',
                component: () => import(/* webpackChunkName: "ui-badge" */ '../views/ui/Badge.vue')
            },
            {
                path: 'breadcrumb',
                name: 'breadcrumbs',
                component: () => import(/* webpackChunkName: "ui-breadcrumb" */ '../views/ui/Breadcrumb.vue')
            },
            {
                path: 'buttons',
                name: 'buttons',
                component: () => import(/* webpackChunkName: "ui-buttons" */ '../views/ui/Buttons.vue')
            },
            {
                path: 'calendar',
                name: 'calendars',
                component: () => import(/* webpackChunkName: "ui-calendar" */ '../views/ui/Calendar.vue')
            },
            {
                path: 'cards',
                name: 'cards',
                component: () => import(/* webpackChunkName: "ui-cards" */ '../views/ui/Cards.vue')
            },
            {
                path: 'dropdown',
                name: 'dropdown',
                component: () => import(/* webpackChunkName: "ui-dropdowns" */ '../views/ui/Dropdowns.vue')
            },
            {
                path: 'modal',
                name: 'modals',
                component: () => import(/* webpackChunkName: "ui-modal" */ '../views/ui/Modal.vue')
            },
            {
                path: 'notifications',
                name: 'notifications',
                component: () => import(/* webpackChunkName: "ui-notifications" */ '../views/ui/Notifications.vue')
            },
            {
                path: 'pagination',
                name: 'pagination',
                component: () => import(/* webpackChunkName: "ui-pagination" */ '../views/ui/Pagination.vue')
            },
            {
                path: 'panels',
                name: 'panels',
                component: () => import(/* webpackChunkName: "ui-panels" */ '../views/ui/Panels.vue')
            },
            {
                path: 'progress',
                name: 'progress',
                component: () => import(/* webpackChunkName: "ui-progress" */ '../views/ui/Progress.vue')
            },
            {
                path: 'spinners',
                name: 'spinners',
                component: () => import(/* webpackChunkName: "ui-spinners" */ '../views/ui/Spinners.vue')
            },
            {
                path: 'table',
                name: 'tables',
                component: () => import(/* webpackChunkName: "ui-table" */ '../views/ui/Table.vue')
            },
            {
                path: 'tabs',
                name: 'tabs',
                component: () => import(/* webpackChunkName: "ui-tabs" */ '../views/ui/Tabs.vue')
            },
            {
                path: 'time',
                name: 'time',
                component: () => import(/* webpackChunkName: "ui-time" */ '../views/ui/Time.vue')
            },
            {
                path: 'typography',
                name: 'typography',
                component: () => import(/* webpackChunkName: "ui-typography" */ '../views/ui/Typography.vue')
            },
            {
                path: 'input',
                name: 'forms',
                component: () => import(/* webpackChunkName: "forms-elements" */ '../views/forms/FormElements.vue')
            },
            {
                path: 'colour',
                name: 'colours',
                component: () => import(/* webpackChunkName: "ui-colours" */ '../views/ui/Colour.vue')
            },
            {
                path: 'popover',
                name: 'popovers',
                component: () => import(/* webpackChunkName: "ui-popover" */ '../views/ui/Popovers.vue')
            },
            {
                path: 'navbar',
                name: 'navbars',
                component: () => import(/* webpackChunkName: "ui-navbar" */ '../views/ui/Navbars.vue')
            },
            {
                path: 'kanban',
                name: 'kanban',
                component: () => import(/* webpackChunkName: "ui-kanban" */ '../views/ui/Kanban.vue')
            }
        ],

    },

    {
        path: '/layouts',
        component: MainNavigationLayout,
        children: [
            {
                path: 'menu',
                name: 'menu',
                components: {
                    'mainView': () => import(/* webpackChunkName: "layouts-menu" */ '../views/pages/Menu.vue')
                }
            },
            {
                path: 'off-canvas',
                name: 'off-canvas',
                components: {
                    'mainView': () => import(/* webpackChunkName: "off-canvas-page" */ '../views/pages/OffCanvasSets.vue'),
                    'panelView': () => import(/* webpackChunkName: "off-canvas-panel" */ '../views/panels/OffCanvas.vue')
                }

            },
            {
                path: 'dashboard',
                name: 'dashboard',
                components: {
                    'mainView': () => import(/* webpackChunkName: "dashboard" */ '../views/pages/Dashboard'),
                }

            },
            {
                path: 'page-layouts',
                name: 'page-layouts',
                components: {
                    'mainView': () => import(/* webpackChunkName: "page-layouts" */ '../views/pages/PageLayouts'),
                }

            },
            {
                path: 'profile',
                name: 'profile',
                components: {
                    'mainView': () => import(/* webpackChunkName: "profile" */ '../views/pages/Profile'),
                }

            },
            {
                path: 'profile-off-canvas',
                name: 'profile-off-canvas',
                components: {
                    'mainView': () => import(/* webpackChunkName: "profile" */ '../views/pages/Profile'),
                    'panelView': () => import(/* webpackChunkName: "profile-off-canvas-panel" */ '../views/panels/ProfileOffCanvas.vue')
                }

            },

        ]
    },

    {
        path: '/page',
        component: WizardLayout,
        children: [
            {
                path: 'wizard-navigation',
                name: 'wizard-navigation',
                component: () => import(/* webpackChunkName: "wizard-navigation" */ '../views/pages/WizardNavigation.vue')
            },


        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
